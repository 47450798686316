import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  position: relative;
  min-height: 300px;
  height: auto;
  margin-top: -1.75rem;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
  
  @media (max-width: 575px) {
    max-height: 250px;
    min-height: unset;
  }

  /* Banner for: Breaking the Myth of Brodeur */
  /* Banner for: Intro to Grub on the Thames */
  &[id="50854828-9312-5c4f-afeb-69037735a555"],
  &[id="2fd2abdf-8433-5db7-9965-c0ce812f528a"] {
    img {
      object-position: top center !important;
    }
  }

  /* Banner for: Millenial's Cry for Housing */
  /* Banner for: Ball Hockey Will Grow the Game */
  &[id="7ebc3c51-dcfa-57b5-80ea-f32bd6acf383"],
  &[id="5710d354-6a10-5ec8-9fcc-21484a53b609"] {
    img {
      height: 120% !important;
    }
  }

  /* Banner for: Everyone Should Listen to: Injury Reserve */
  &[id="ba8ac092-c6fe-59f3-9b26-b82227bf1a77"] {
    @media (min-width: 767px) {
      img {
        height: 175% !important;
      }
    }
  }

  /* Banner for: A Very Yukon Scandal */
  &[id="adc9121e-8b0b-5d80-9efe-b445a757b87c"] {
    img {
      height: 150% !important;
    }
  }

  /* Banner for: Videotapes April 2021 */
  &[id="427dedf3-0d0f-5f0f-9deb-a3f0b2b7291e"] {
    img {
      height: 165% !important;
      object-position: top center !important;

      @media (max-width: 766px) {
        height: 130% !important;
        top: 25px !important;
      }
    }
  }

  /* Banner for: Everyone Should Listen To: King Gizzard */
  &[id="e09d0e61-2551-515b-acec-9d70c0237446"] {
    img {
      object-position: top center !important;
    }
  }

  /* Banner for This Team is Ruining My Life */
  &[id="7f5060e9-38ea-5436-b255-6a3232a89458"] {
    img {
      height: 185% !important;
    }
  }

  /* Banner for Everyone Should Listen To: Aguia Nao Come Mosca */
  &[id="fe4d0595-a4ae-518d-a004-2cd56d3276b3"] {
    img {
      height: 245% !important;
    }
  }
`
const BgImg = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: 575px) {
    max-height: 250px;
  }

  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

const Hero = props => (
  <Wrapper id={props.id} height={props.height}>
    <BgImg fluid={props.image.fluid} backgroundColor={'#eeeeee'} />
  </Wrapper>
)

export default Hero

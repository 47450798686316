import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import SocialShareList from '../SocialShare/SocialShareList';

const List = styled.ul`
  width: 100%;
  margin: 0 auto 1rem auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  ul {
    @media (min-width: 893px) {
      display: none;
    }
  }

  @media (max-width: 893px) {
    display: flex;
    position: relative;
    margin: 1rem auto 2rem;

    ul {
      flex-direction: row;
    }
  }

  @media (max-width: 420px) {
    flex-wrap: wrap;

    ul {
      position: unset;
      width: 100%;
    }
  }

  li:first-of-type {
    a {
      padding-left: 0;
    }
  }

  li:nth-of-type(2) {
    a {
      &::after {
        ${props => props.theme.outlines.tileSectionBorderRight}
        right: -0.25rem;
      }
      
      &::before {
        ${props => props.theme.outlines.tileSectionBorderLeft}
        left: -0.25rem;
      }
    }
  }
`

const TagList = props => {
  return (
    <List>
      {/* {props.tags.map(tag => (
        <Tag key={tag.id}>
          <StyledLink to={`${props.basePath}/category/${tag.slug}/`}>{tag.title}</StyledLink>
        </Tag>
      ))} */}
      <SocialShareList iconSize={'32'} />
    </List>
  )
}

export default TagList

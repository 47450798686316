import React from 'react'
import styled from '@emotion/styled'
require('prismjs/themes/prism.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 500;
  text-align: justify;

  @media (max-width: 790px) {
    text-align: left;
  }

  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 0.75rem;
    top: -1.25rem;
    text-align: center;
    font-weight: normal;
    color: ${props => props.theme.colors.secondary};
    pointer-events; none;
    font-style: italic;

    svg {
      display: none;
    }
  }

  p {
    line-height: 1.6;
    margin: 0 0 1.5rem 0;
    font-size: 1.25rem;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.text};

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }

  del {
    text-decoration: line-through;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 1.5rem 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;

      &:last-child {
        margin: 0;
      }
    }

    &.custom-list {
      li {
        font-size: 1.25rem;
        margin: 0 0 1.5rem 0;
        line-height: 1.6;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;

      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 1.5rem 0;
  }

  blockquote {
    font-style: italic;
    border-left: 0.25rem solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5rem;
  }

  pre {
    margin: 0 0 1.5rem 0;
    border-radius: 0.125rem;
    background: ${props => props.theme.colors.secondary} !important;
    
    span {
      background: inherit !important;
    }
  }

  .underline {
    text-decoration: underline;
  }

  .bold-text {
    font-weight: bold;
  }

  .endnote {
    font-size: 0.9rem;
  }

  .poem--container {
    p {
      margin-bottom: 0;

      @media (max-width: 450px) {
        font-size: 16px;
      }
    }

    .poem-indent {
      margin-left: 55px;
    }
  }

  .poem--section__indent {
      margin-top: 30px;
      text-align: right;

      p:first-of-type {
        margin-right: 105px;
      }

      p:nth-of-type(2) {
        margin-right: 62px;
      }
  }

  .table-scroll {
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: auto;
    max-height: 500px;
    scrollbar-color: inherit #F9F4EF;
  }

  .table-scroll table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;

    &.table--fixed {
      margin-bottom: 1.25rem;
      min-width: unset;
    }
  }

  .table-wrap {
    position: relative;
  }

  .table-scroll th,
  .table-scroll td {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    vertical-align: top;

    &:nth-of-type(7){
      .rhi,
      .kev {
        display: inline-block;
        width: 60px;
      }
    }

    .rhi {
      color: #98e8c2;
    }

    .kev {
      color: #496075;
      display: inline-block;
      margin-top: 8px;
    }
  }

  .table-scroll thead th {
    background: #423E3D;
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  th:first-of-type,
  td:first-of-type {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #E6C073;
  }

  thead th:first-of-type,
  tfoot th:first-of-type {
    z-index: 5;
  }

  .reviewer-legend {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-botton: 1rem;

    span {
      display: inline-block;
      margin-top: 2px;
      padding-left: 16px;
      position: relative;

      &::before {
        border-radius: 5px;
        content: "";
        height: 10px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 10px;
      }

      &.rhi {
        &::before {
          background-color: #98e8c2;
        }
      }

      &.kev {
        &::before {
          background-color: #496075;
      }
    }
  }
`

const PageBody = props => {
  return (
    <Body
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default PageBody

import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3.75rem auto 0;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  min-height: 200px;
  position: relative;

  &::after {
    ${props => props.theme.outlines.tileSectionBorderTop}
    top: -1.25rem;
  }

  @media (max-width: 765px) {
    display: none;
  }
  
  a {
    border-radius: 0.125rem;
    text-decoration: none;
    transition: 0.2s;
    position: relative;
    z-index: 3;
  }
`

const LinkContainer = styled.div`
  position: relative;
  width: calc(50% - 0.625rem);

  &.previous-container {
    order: 1;

    &::after {
      ${props => props.theme.outlines.tileSectionBorderRight};
      right: -0.313rem;
    }
  }

  &.next-container {
    order: 2;
  }
`

const PreviousLink = styled(Link)`
  overflow: visible;
`

const NextLink = styled(Link)`
  span {
    display: block;
    width: 100%;
    text-align: right;
  }
`

const LinkDetails = styled.header`
  height: 80%;
  color: black;
  margin-top: 1rem;
`

const LinkDirectionLabel = styled.span`
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 400;
  font-style: italic;
  font-size: 1.25rem;
  color: ${props => props.theme.colors.secondary};
`

const ArticleTitle = styled.h4`
  font-family: ${props => props.theme.fonts.title};
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  min-height: 3.125rem;
`

const ArticleDescription = styled.p`
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 400;
  font-size: 1rem;
`

const PostLinks = props => {

  return (
      <Box>
        <LinkContainer className="previous-container">
          {props.previous && (
            <PreviousLink to={`${props.basePath}/${props.previous.slug}/`} >
                <LinkDirectionLabel>&#8592; Prev</LinkDirectionLabel>
                <LinkDetails>
                  <ArticleTitle>{props.previous.title}</ArticleTitle>
                  <ArticleDescription>
                    {props.previous.metaDescription.childMarkdownRemark.excerpt}
                  </ArticleDescription>
                </LinkDetails>
            </PreviousLink>
          )}
        </LinkContainer>
        <LinkContainer className="next-container">
          {props.next && (
          <NextLink to={`${props.basePath}/${props.next.slug}/`} >
                <LinkDirectionLabel>Next &#8594;</LinkDirectionLabel>
                <LinkDetails>
                  <ArticleTitle>{props.next.title}</ArticleTitle>
                  <ArticleDescription>
                  {props.next.metaDescription.childMarkdownRemark.excerpt}
                  </ArticleDescription>
                </LinkDetails>
            </NextLink>
          )}
        </LinkContainer>
      </Box>
  )
}

export default PostLinks

import React from 'react'
import styled from '@emotion/styled'

const ImageCaption = styled.h4`
    font-family: ${props => props.theme.fonts.copy};
    font-weight: 400;
    font-style: italic;
    font-size: 8px;
    width: 100%;
    margin: 0;
    text-align: right;
    padding-right: 1%;
    
    a {
        color: ${props => props.theme.colors.secondary};
    }
`

const HeroImageCaption = ({ captionText }) => {
    return (
        <ImageCaption
            dangerouslySetInnerHTML={ { __html: captionText} }
        />
    )
}

export default HeroImageCaption

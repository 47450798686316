import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Post/HeroBanner/Hero'
import Container from '../components/Container'
import ContentContainer from '../components/ContentContainer';
import PageBody from '../components/Post/PageBody'
import ContactUsCTA from '../components/CTAs/ContactCTA';
import TagList from '../components/Post/TagList'
import PostLinks from '../components/Post/PostLinks'
import RecoPanelLinks from '../components/Post/RecoSidePanel';
import SocialShareAside from '../components/SocialShare/SocialShareAside';
import AuthorDetails from '../components/Post/PostAuthorBlock';
import PostTitle from '../components/Post/PostTitle';
import HeroImageCaption from '../components/Post/HeroBanner/PostHeroImageCaption';
import SEO from '../components/SEO'
import { updateSearchValue } from '../actions/valueChanges';


const PostTemplate = ({ data, pageContext }) => {
  const {
    id,
    title,
    metaDescription,
    heroImage,
    body,
    tags,
    authors
  } = data.contentfulPost
  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext
  const posts = pageContext.posts;
  const dispatch = useDispatch();

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  const heroImageCaptionText = heroImage.description ? heroImage.description : null;

  useEffect(() => {
    dispatch(updateSearchValue(""));
  }, [])

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Hero title={title} image={heroImage} height={'52vh'} id={id} />
        {heroImageCaptionText === null ?
          null
          :
          <HeroImageCaption captionText={heroImageCaptionText} />
        }
      <ContentContainer>
        <SocialShareAside />
        <Container contentPage={true} id={id}>
          <PostTitle title={title} />
          {authors === null ? null : <AuthorDetails author={authors} />}
          <TagList />
          <PageBody body={body} />
          <ContactUsCTA page={'post'} />
          <PostLinks previous={previous} next={next} basePath={basePath} />

        </Container>
        <RecoPanelLinks posts={posts} basePath={basePath} currentPostId ={id} title={"Explore More"} />
      </ContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      slug
      test
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      authors {
        title
        id
        slug
        authorThumbnail {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          ogimg: resize(width: 1800) {
            src
          }
        }
        authorDescription {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 320)
          }
        }
      }
      heroImage {
        title
        description
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate

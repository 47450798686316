import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.875rem 1% 0;
  
  @media (max-width: 766px) {
    padding-top: 0;
  }
`

const ContentContainer = props => {
    return <Wrapper>{props.children}</Wrapper>
}

export default ContentContainer

import React from 'react'
import styled from '@emotion/styled'

const AuthorDetailsWrapper = styled.section`
  margin: 0 auto 2rem;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  display: flex;
  align-items: center;

  @media (max-width: 867px) {
    margin: 0 auto;
  }

  span {
    margin: 0 0.5rem;
  }
  
  img {
      min-width: 75px;
      width: 10%;
      border-radius: 50%;
      margin-right: 0.9rem;
  }
`

const AuthorName = styled.h2`
  font-weight: 600;
  font-family: ${props => props.theme.fonts.title};
  font-size: 1.25rem;
`

const PodcastName = styled.h2`
  font-weight: 600;
  font-family: ${props => props.theme.fonts.title};
  font-size: 2rem;
`

const AuthorDetails = ({ author, contentType }) => {
    const authorName = 
    author[0].title ? 
    <AuthorName>By: {author[0].title}</AuthorName> 
    : null;

    const podcastGroupName = 
    author[0].title && contentType === 'podcast' ?
    <PodcastName>{author[0].title}</PodcastName>
    : null;

    const authorThumbnail = 
    author[0].authorThumbnail ? 
    <img src={author[0].authorThumbnail.ogimg.src} alt={`${authorName} Thumbnail`}/>
    : null;

    return (
        <AuthorDetailsWrapper>
            {authorThumbnail}
            {contentType === 'podcast' ? podcastGroupName : authorName}
        </AuthorDetailsWrapper>
    )
}

export default AuthorDetails

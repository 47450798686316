import React from 'react'
import styled from '@emotion/styled'
import SocialShareList from './SocialShareList';

const SocialShare = styled.aside`
    @media (max-width: 893px) {
        display: none;
    }
`

const fbBackground = {
    fill: 'red',
}

const SocialShareAside = () => {
    const url = typeof window !== `undefined` ? window.location.href : '';

    return (
        <SocialShare>
            <SocialShareList iconSize={40} />
        </SocialShare>
    )
}

export default SocialShareAside;
